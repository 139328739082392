import SEO from '@components/Common/SEO/index';
import Image from '@components/extra/image';
import useResizer from '@components/extra/useResizer';
import React from 'react';
import { GTM } from '../../analytics/gtm';
import { gtmEvents } from '../../analytics/gtmEventsConstant';
import db from '../../data/db.json';
import * as styles from './Bytes.module.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';
import FooterV2 from '@components/FooterV2/index';
import Navigation from '@components/Navigation/index';

let str = '';

export default function Bytes({ data }) {
  const isMobile = useResizer();
  let programData = db['bytes'];
  const sale = data.strapiSale.SaleID;

  return (
    <>
      <SEO
        title="Crio Bytes"
        description="Get real software development experience. Work on challenging projects. Learn in-demand tech skills to excel in your career"
      ></SEO>
      {/* <div className="fixed-header">
        <PromotionBanner sale={sale} location={'BYTES'}></PromotionBanner>
        <Header></Header>{' '}
      </div> */}
      <Navigation />
      <div
        style={{
          background: '#F3F1EE',
          // padding: "40px 20px",
        }}
      >
        <div className="container  ">
          <section className="pt-36 pb-14">
            <div className="container  ">
              <div className="row flex h-full flex-wrap items-center">
                <div className="col-12 col-lg-7 mb-4 flex  flex-col  justify-center p-0 text-center md:text-left ">
                  <h3 style={{ color: '#74715D' }} className="font-normal ">
                    A little taste of #learnbydoing.
                  </h3>
                  <h1 style={{ color: '#075353' }}>Introducing Crio Bytes.</h1>
                </div>

                <div className="   col-12 col-lg-5 mb-5 flex items-center md:mb-0">
                  <StaticImage
                    loading="lazy"
                    src="../../images/bytes/header.png"
                    placeholder="blurred"
                    alt="Bytes"
                    style={{ width: '500px' }}
                  />
                  {/* <Image
                    filename="bytes/header.png"
                    style={{ width: '500px' }}
                  ></Image> */}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div className={`pt-14 pb-6 ${styles.bytesSection}`}>
        <div className="container   pb-20">
          {programData.map((program, idx) => (
            <div
              key={idx}
              className=""
              id={program.id}
              style={{ scrollMarginTop: '120px' }}
            >
              <div className="row flex items-center">
                <div className="col-lg-12">
                  <div className={styles.byteTopicCard}>
                    <div className="mb-4 flex items-center">
                      <div className="mr-2">
                        <Image
                          filename={program.icon}
                          style={{ width: '40px' }}
                        ></Image>
                      </div>
                      <h5 className="m-0 p-0 font-normal">
                        {program.programName}
                      </h5>
                    </div>
                    <p className="mb-3">
                      <strong>Description:</strong> {program.description}
                    </p>
                    <p className="mb-2">
                      <strong>Skills:</strong>
                    </p>
                    <div className="flex flex-wrap">
                      {program.skills.map((ele, idx) => (
                        <div key={idx} className={styles.skill}>
                          {ele}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="row flex flex-wrap">
                    {program.meList.map((me, idx) => (
                      <span className="col-12 col-lg-4 mb-4 " key={idx}>
                        {' '}
                        <a
                          href={'/bytes/' + me.url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {' '}
                          <div
                            className={styles.byteCard}
                            onClick={() => {
                              GTM.track(gtmEvents.BYTE_CARD_CLICKED, {
                                meId: me.meId,
                              });
                            }}
                          >
                            <div className="relative flex h-[200px] flex-col items-center justify-center overflow-hidden rounded-lg text-center ">
                              <h5 className="absolute top-1/2  left-0  right-0 z-10 z-10 -translate-y-1/2 transform  px-3 ">
                                {me.meTitle}
                              </h5>
                              <StaticImage
                                loading="lazy"
                                src="../../images/web-bg.png"
                                placeholder="blurred"
                                alt="Background"
                              />
                            </div>
                            {/* 
                            <StyledBackgroundSection
                              image={program.bg}
                              style={{
                                overflow: 'hidden',
                                borderRadius: '5px',
                                height: '100%',
                                padding: '30px',
                              }}
                            >
                              <h5 className=" mb-4">{me.meTitle}</h5>
                              <p>{me.meSummary}</p>
                            </StyledBackgroundSection> */}{' '}
                          </div>{' '}
                        </a>
                      </span>
                    ))}
                  </div>
                </div>
              </div>{' '}
              {idx !== programData.length - 1 ? (
                <div
                  style={{
                    margin: '30px 0px 50px 0px',
                    borderBottom: '1px solid #075353',
                  }}
                ></div>
              ) : (
                ''
              )}
            </div>
          ))}
        </div>
      </div>
      {/* <Footer></Footer> */}
      <FooterV2 />
    </>
  );
}

// export default AboutUsPage;

export const pageQuery = graphql`
  query {
    strapiSale {
      ...strapiSaleFragment
    }
  }
`;
